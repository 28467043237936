<template>
  <div class='file browser field'>
    <div class='ui fluid action input'>
      <input :value='fileName' disabled name='name' placeholder='Browse...' type='text'>
      <div :disabled='!pickerApiLoaded' @click='browse' class='ui right labeled blue icon button'>
        <i class='google drive icon'></i>
        Browse
      </div>
    </div>
    <input :value='fileName' class='hidden' name='value[file_name]' type='hidden'>
    <input :value='embedUrl' class='hidden' name='value[embed_url]' type='hidden'>
    <input :value='link' class='hidden' name='value[link]' type='hidden'>
  </div>
</template>

<script>
  export default {
    props: {
      existingFileName: String,
      existingEmbedUrl: String,
      existingLink: String
    },
    data() {
      return {
        client: null,
        fileName: null,
        embedUrl: null,
        link: null,
        picked: {},
        pickerApiLoaded: false,
        oauthToken: null,
        config: {
          clientId: '313527239949-nmurf7c1rr96aac0jn09mmofeo0hovd5.apps.googleusercontent.com',
          scope: 'https://www.googleapis.com/auth/drive.file',
          developerKey: 'AIzaSyAu4kLPDeVLakQ2Zgcbrs56wwKkBK8ORdA',
          appId: '313527239949'
        }
      }
    },
    created() {
      this.fileName = this.existingFileName
      this.embedUrl = this.existingEmbedUrl
      this.link = this.existingLink
    },
    mounted() {
      this.client = google.accounts.oauth2.initTokenClient({
        client_id: this.config.clientId,
        scope: this.config.scope,
        callback: this.handleAuthResult,
        prompt: ''
      })

      gapi.load('picker', () => {
        this.pickerApiLoaded = true
      })
    },
    methods: {
      browse() {
        this.client.requestAccessToken()
      },
      handleAuthResult(authResult) {
        if (authResult && !authResult.error) {
          this.oauthToken = authResult.access_token
          this.createPicker()
        }
      },
      createPicker() {
        if (this.pickerApiLoaded && this.oauthToken) {
          const picker = new google.picker.PickerBuilder()
            .addView(google.picker.ViewId.DOCS)
            .enableFeature(google.picker.Feature.NAV_HIDDEN)
            .setOAuthToken(this.oauthToken)
            .setDeveloperKey(this.config.developerKey)
            .setAppId(this.config.appId)
            .setCallback(this.pickerCallback)
            .build()

          picker.setVisible(true)
        }
      },
      pickerCallback(data) {
        if (data[google.picker.Response.ACTION] === google.picker.Action.PICKED) {
          const file = data[google.picker.Response.DOCUMENTS][0]
          this.fileName = file.name
          this.embedUrl = file.embedUrl
          this.link = file.url
        }
      }
    }
  }
</script>
